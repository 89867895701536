import { Link } from "gatsby"
import React from "react"
import flatartLogo from "../assets/img/flatart-logo.png"
import instagram from "../assets/img/instagramdeneme.png"
import btniletisim from "../assets/img/rapor.png"
import btnrapor from "../assets/img/hizli_iletisim.webp"



const Footer = () => (
 
  <footer>
    <div className="footer-bottom">
      
    {/* <div class="fixed-widget-cta">
	<div class="widget-first-cta">
		<a href="tel:+908509880200"><img alt="alt text" src={btnrapor} /></a>
	</div>
	<div class="widget-first-cta">
		<a href="/proje-baslat/" ><img alt="alt text" src={btniletisim} /></a>
    
	</div>
</div>*/}

      <div className="footer-bottom_content">
        <h2>Tanışalım.</h2>
        <p>
          <a href="mailto:hello@theflatart.com">E-posta</a> veya{" "}
          <a href="tel:+90(850)9880200">telefon</a> aracılığıyla iletişime
          geçelim
        </p>
        <p>ya da ajansımızda birer kahve içelim.</p>
      </div>
      <div className="footer-bottom_links container">
        <div className="col-md-3 social" style={{ textAlign: "center" }}>
          <img className="footer-logo" alt="Flatart logo" src={flatartLogo} />
        
        </div>

        <div className="col-md-3">
          <p class="footerlike">Hizmetler</p>
          <ul>
            <li>
              <Link to="/seo-ajansi/">
                Arama Motoru Optimizasyonu
              </Link>
            </li>
            <li>
              <Link to="/sosyal-medya/">Sosyal Medya Yönetimi</Link>
            </li>
            <li>
              <Link to="/eposta-pazarlama/">E-posta Pazarlama</Link>
            </li>
            <li>
              <Link to="/web-mobil-gelistirme/">Web Geliştirme</Link>
            </li>
            <li>
              <Link to="/ui-ux-tasarim/">UI/UX Tasarım</Link>
            </li>
            <li>
              <Link to="/digital-ads/">Digital Ads</Link>
            </li>
          </ul>
        </div>
        <div className="col-md-3">
          <p class="footerlike">Çözümler</p>
          <ul>
            <li>
              <Link to="/trafik-edinme/">Trafik Edinme</Link>
            </li>
            <li>
              <Link to="/donusum/">Dönüşüm</Link>
            </li>
            <li>
              <Link to="/analiz/">Analiz</Link>
            </li>
            {/* <li><Link to="#">Lead Generation</Link></li> */}
            {/* <li><Link to="#">CRO</Link></li> */}
          </ul>
        </div>
        <div className="col-md-3">
        <p class="footerlike">Flatart Agency</p>
          <ul>
            <li>
              <Link to="/hakkimizda/">Hakkımızda</Link>
            </li>
            <li>
              <Link to="/hakkimizda/">Tanışalım</Link>
            </li>
            <li>
              <Link to="/markalar/">Markalar</Link>
            </li>
            <li>
              <a href="https://lu.ma/d22c053g">AI Meetup</a>
            </li>
            <li>
              <Link to="/iletisim/">İletişim</Link>
            </li>
            <li>
              <Link to="/hakkimizda/#ekip">Ekip</Link>
            </li>
            <li>
              <Link to="/hakkimizda/#ik">İK</Link>
            </li>
            {/* <li><Link to="#">Analiz Danışmanlığı</Link></li>
                        <li><Link to="#">Analitik Entegrasyonlar</Link></li>
                        <li><Link to="#">Büyüme Planı</Link></li> */}
          </ul>
        </div>
        <div className="col-md-3">
        <p class="footerlike">Bizi Takip Et</p>
          <ul>
          <li>
          <a href="https://www.instagram.com/flatart.agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 154.4 140.85"><path class="cls-1" d="M38.79,53.96c.72-3.74,1.39-7.5,3.43-10.83,4.65-7.59,11.93-10.51,20.31-10.71,11.44-.28,22.89-.27,34.33,0,7.77,.19,14.68,2.75,19.41,9.48,3.04,4.33,4.18,9.29,4.26,14.42,.17,10.99,.24,22,0,32.99-.2,8.85-3.28,16.4-11.57,20.9-3.83,2.08-8.02,2.98-12.31,3.03-11.29,.13-22.59,.24-33.88-.02-8.47-.19-15.83-3.05-20.54-10.73-2.04-3.33-2.71-7.08-3.43-10.83V53.96Zm40.97-13.79c-5.61,0-11.22-.11-16.83,.02-8.76,.21-14.15,4.31-15.74,11.85-.34,1.6-.59,3.26-.6,4.89-.05,10.62-.19,21.25,.02,31.87,.22,10.57,5.56,16.22,15.75,16.59,11.51,.42,23.04,.39,34.56,0,8.49-.28,13.54-4.37,15.16-11.7,.29-1.31,.56-2.66,.57-3.99,.06-12.11,.73-24.23-.37-36.32-.65-7.16-5.44-11.84-12.58-12.82-6.63-.91-13.3-.26-19.94-.4Z"/><path class="cls-1" d="M100.86,72.96c-.03,11.41-9.5,20.79-21.13,20.93-11.66,.14-21.34-9.49-21.3-21.2,.03-11.51,9.86-21.15,21.35-20.93,11.79,.22,21.11,9.6,21.08,21.2Zm-7.87-.1c.01-7.26-5.97-13.21-13.32-13.25-7.3-.04-13.37,5.94-13.38,13.18-.01,7.26,5.97,13.21,13.32,13.25,7.3,.04,13.37-5.94,13.38-13.18Z"/><path class="cls-1" d="M101.44,46.22c2.81-.04,5.19,2.29,5.18,5.07-.02,2.66-2.3,4.94-5.02,5.02-2.8,.08-5.24-2.21-5.29-4.97-.05-2.79,2.24-5.08,5.13-5.11Z"/></svg>
              Instagram
            </a>
            </li>
            <li>
          <a href="https://www.linkedin.com/company/flatart-agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path class="cls-1" d="M54.29,40.94c0,4.78-3.04,8.68-8.68,8.68-5.21,0-8.68-3.91-8.68-8.25,0-4.78,3.47-9.12,8.68-9.12s8.68,3.91,8.68,8.68Z"/><rect class="cls-1" x="36.93" y="53.96" width="17.37" height="56.44"/><path class="cls-1" d="M95.97,54.83c-9.12,0-14.33,5.21-16.5,8.68h-.43l-.87-7.38h-15.63c0,4.78,.43,10.42,.43,16.93v37.34h17.37v-30.83c0-1.74,0-3.04,.43-4.34,1.3-3.04,3.47-6.95,8.25-6.95,6.08,0,8.68,5.21,8.68,12.16v29.96h17.37v-32.13c0-16.06-8.25-23.45-19.1-23.45Z"/></svg>
          LinkedIn
            </a>
            </li>
            <li>
          <a href="https://www.facebook.com/flatart.agency/" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path class="cls-1" d="M91.33,44.77h7.24v-15.75h-10.9c-15.44-.01-22.77,9.18-22.77,22.27v12.4h-12.09v14.97h12.09v38.07h18.14v-38.07h12.09l3.43-14.96h-15.53v-10.22c0-4.18,2.29-8.7,8.3-8.7Z"/></svg>
          Facebook
            </a>
            </li>
            <li>
          <a href="https://twitter.com/flatartagency/" class="email-services_list"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
<g fill="#8c8c8c"><g transform="scale(8,8)"><path d="M4.01758,4l9.07422,13.60938l-8.75586,10.39063h2.61523l7.29492,-8.65625l5.77148,8.65625h0.53516h7.46289l-9.30273,-13.95703l8.46289,-10.04297h-2.61523l-7.00195,8.31055l-5.54102,-8.31055zM7.75586,6h3.19141l13.33203,20h-3.19141z"></path></g></g>
</svg>
          Twitter
            </a>
            </li>
            <li>
          <a href="https://www.youtube.com/@flatartagency6353" class="email-services_list"><svg id="OBJECTS" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 154.4 140.85"><path d="M94.31,71.33c.04,.2,.05,.39,.04,.59,.04-.2,.04-.4-.04-.59Z"/><path class="cls-1" d="M79.51,38.99c-41.77,0-42.5,3.71-42.5,32.73s.73,32.73,42.5,32.73,42.5-3.71,42.5-32.73-.73-32.73-42.5-32.73Zm14.83,32.93c-.05,.78-.47,1.54-1.29,1.93l-19.18,9.27c-1.54,.75-3.33-.38-3.33-2.09v-18.12c0-1.69,1.76-2.82,3.3-2.11l19.18,8.85c.74,.34,1.16,.98,1.29,1.68,.08,.2,.08,.4,.04,.59Z"/></svg>
          Youtube
            </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom_end">
        <div className="footer-bottom_end-content container">
          <p>
            flatart 2024 Tüm Hakları Saklıdır.
          </p>
          <ul>
            <li>
              <Link to="/gizlilik-sozlesmesi/">Gizlilik Sözleşmesi</Link>
            </li>
            <li>
              <Link to="/kullanici-antlasmasi/">Kullanıcı Antlaşması</Link>
            </li>
            <li>
              <a target="_blank" href="https://popupsmart.com">
                Popupsmart
              </a>
            </li>
             <li>
              <a target="_blank" href="https://mylandingbuilder.com/">
                MyLandingBuilder
              </a>
            </li>
            {/* <li><Link to="#">Gizlilik Sözleşmesi</Link></li> */}
            {/* <li><Link to="#">Kullanım Koşulları</Link></li> */}
          </ul>
        </div>
      </div>
    </div>
   
  </footer>
)

export default Footer
